@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

body {
  @apply bg-dark-grey text-white font-montserrat;
}

html, body, #root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #1a1a1a;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  @apply bg-dark-grey text-white;
}